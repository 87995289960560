
import './App.css';
import Navbar from "./components/Navbar"
import { Route, Routes } from "react-router-dom";
import List from "./pages/List";
import Resources from "./pages/Resources";
import About from "./pages/About";
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const TRACKING_ID = "G-W9TSDWZJ1V"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  

  return (
    
    <>
    
    <div className="main">

        <h1 className="header">MC List</h1><a href="https://www.tryexponent.com/?ref=mclist">by Exponent</a>
      
        
      <div className="nav">
        <Navbar/>
      </div>
    
      <Routes>
        
        <Route path="/" element={<List />} />
        <Route path="/about" element={<About />} />
        <Route path="resources" element={<Resources />} />

      </Routes>

  
    </div>



    </>

  );
}

export default App;
