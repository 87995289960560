import React, {useState} from 'react'
import "./Navbar.css"
import { Link } from "react-router-dom"

function Navbar() {
  return (
    <>
    <nav className='navbar'>
    <ul>
        <li>
            <Link to="/">The List</Link>
        </li>
        <li>
            <Link to="/resources">Resources</Link>
        </li>
        <li>
            <Link to="/about">About</Link>
        </li>
        <li>
            <a href="https://www.apmlist.com">APM List</a>
        </li>
    </ul>
    </nav>
    </>
  )
}

export default Navbar
