import "./FullTable.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';

const eventTrack = (category, action, label) => {
    console.log("GA event: McKinsey", category, "Click", action, "Full-Time", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

function FullTable() {
  return (
      
    <div className="FullTable">
        <table>
            <tr>
                <th>Company</th>
                <th>Status</th>
            </tr>

            <tr className="body-row">
                <td><a href="https://www.mckinsey.com/careers/search-jobs/jobs/businessanalyst-15136" target="_blank" rel="noreferrer">McKinsey</a></td>
                
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>  
            </tr>

            <tr className="body-row">
                <td><a href="https://www.bain.com/careers/roles/ac/" target="_blank" rel="noreferrer"> Bain </a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>
            
            <tr className="body-row">
                <td><a href="https://talent.bcg.com/en_US/apply/FolderDetail/Full-time-Application/10013666" target="_blank" rel="noreferrer">BCG </a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>  
            </tr>

            <tr className="body-row">
                <td><a href="https://jobs.us.pwc.com/job/los-angeles/strategy-and-deals-strategy-associate-summer-fall-2023/932/33431422736" target="_blank" rel="noreferrer">Strategy&</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://careers.marshmclennan.com/global/en/job/R_198068/Oliver-Wyman-Consultant-US" target="_blank" rel="noreferrer">Oliver Wyman</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://lek.tal.net/vx/lang-en-GB/mobile-0/appcentre-2/brand-2/xf-adbd39e312ba/candidate/so/pm/1/pl/6/opp/2840-U-S-Associate-2023/en-GB" target="_blank" rel="noreferrer">L.E.K.</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://eyglobal.yello.co/external/requisitions/9w_KtujKerW3-orV_p9PYA" target="_blank" rel="noreferrer">EY-Parthenon</a></td>
                <td> <span class="badge badge-pill bg-success"> Open </span></td>
            </tr>
            <tr className="body-row">
                <td><a href="https://kearney.recsolu.com/jobs/tskObqFUtzI0L5r05ye5Nw?job_board_id=EPd41qlA4_03IncZMnWyRQ" target="_blank" rel="noreferrer">Kearney</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://apply.deloitte.com/careers/JobDetail/Deloitte-Consulting-Analyst-Strategy/93781" target="_blank" rel="noreferrer">Deloitte</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://www.accenture.com/us-en/careers/jobdetails?id=R00098810_en&title=Entry+Level+Analyst+Development+Programs+-+NAELFY23" target="_blank" rel="noreferrer">Accenture</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://studentjobs.ey.com/job/los-angeles/usa-consulting-business-consulting-staff/39053/35319090880" target="_blank" rel="noreferrer">EY</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023  </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://jobs.us.pwc.com/job/phoenix/technology-consulting-associate-summer-fall-2023/932/33490585056" target="_blank" rel="noreferrer">PwC</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023  </span></td>
            </tr>
            
        </table>
  </div>
    );
}

export default FullTable
