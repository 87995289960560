import '../App.css';
import FullTable from '../components/FullTable';
import InternTable from '../components/InternTable';
import DiversityTable from '../components/DiversityTable';
import EmailSignup from "../components/EmailSignup";
import {Helmet} from "react-helmet";

function List() {
    return (
      
      <>
      <Helmet>
        <title>MC List: Management Consulting Job List</title>
      </Helmet>
      <div className="description">
        <p>Here you'll find management consulting programs to apply to along with interview prep resources.</p>
        <p>Management consulting roles train young professionals to become business leaders. Individuals typically spend two years working for these firms before going to business school or exiting into business operations, product management, or investing roles at various top-tier companies.</p>
      </div>

      <div className="emailsignup">
        <EmailSignup/>
      </div>
      
      <div class="title">
            Full Time Programs (2022-2023 Recruiting Cycle)
      </div>
      
      <div class="subtitle">Programs for students entering their senior year of college and looking for full-time roles post-graduation.</div>
      <br></br>
      <div className="description">
        <p>These are all the top tier consulting roles. If something looks wrong, <a href="https://airtable.com/shruZJULQ0nmCDjPw" target="_blank" rel="noreferrer">let us know</a>.</p>
      </div>

    

      <div className="table">
        <FullTable/>
      </div>
      
      <div className="title">
            Intern Programs (2022-2023 Recruiting Cycle)
        </div>
     <div className="subtitle">Programs for students entering their junior year of college and looking for internships the summer before their senior year of college.</div>
    
     <div className="table">
        <InternTable/>
      </div>

      <div className="title">
            Diversity Programs (2022-2023 Recruiting Cycle)
        </div>
     <div className="subtitle">Programs for freshmen and sophomores in college looking for summer internships.</div>
    
     <div className="table">
        <DiversityTable/>
      </div>

     <div className="title">Information Not Updated?</div>
     <br></br>
        <div className="description">
            <p>If you believe the above info is not updated, or you know of a consulting program that should be listed, fill out this <a href="https://airtable.com/shruZJULQ0nmCDjPw" target="_blank" rel="noreferrer">brief form</a> and we will update the list ASAP.</p>
            <p>Is your company not featured on the list? Do you want to add a sponsored consulting role? Reach out to info@apmlist.com. </p>
        </div>
      </>
  
    );
  }
  
  export default List;
  
 
