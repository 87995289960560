import EmailSignup from "../components/EmailSignup";
import {Helmet} from "react-helmet";


function About() {
    return (
      
      <>
      
      <Helmet>
        <title>MC List: About</title>
      </Helmet>

    {/* Want to get in touch? */}

      <div class="title">
            Have questions or comments?
      </div>

      <br></br>
     
      <div className="description">
        <p>For those that want to leave a note or have feature requests, you can email us at info@apmlist.com. </p>
        <p>If something looks incorrect on the list please fill out this <a href="https://airtable.com/shruZJULQ0nmCDjPw" target="_blank" rel="noreferrer" >form</a>.</p>
      </div>
  
    {/* Who made MC List? */}

      <div class="title">
      Who made MC List?
      </div>

      <br></br>

      <div className="description">
        <p>MCList was created by <a href="https://www.linkedin.com/in/seanscheng/" target="_blank" rel="noreferrer">Sean</a>, an incoming Associate Consultant Intern at Bain, and <a href="https://www.linkedin.com/in/ritvik-ramakrishnan/" target="_blank" rel="noreferrer"> Ritvik </a>, an incoming Summer Business Analyst at McKinsey. Today the site is maintained by <a href="https://www.tryexponent.com/?ref=mclist" target="_blank"> Exponent </a>.</p>
        <p>Sean and Ritvik created this site after being frustrated with how difficult it was to know when management consulting job applications opened up while they were recruiting. MCList is inspired by <a href= "https://apmlist.com/" target="_blank"> APM List</a>.</p>
    
      </div>

      <footer> 
      <EmailSignup/>
      </footer>

      </>
  
    );
  }
  
  export default About;