import "./DiversityTable.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function DiversityTable() {
  return (
      
    <div className="DiversityTable">
        <table>
            <tr>
                <th>Program Name</th>
                <th>Status</th>
            </tr>

           
            <tr className="body-row">
                <td><a href="https://www.mckinsey.com/careers/students/sophomore-summer-business-analyst" target="_blank" rel="noreferrer"> McKinsey SSBA</a></td>
                
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>  
            </tr>

            <tr className="body-row">
                <td><a href="https://www.bain.com/careers/bel/" target="_blank" rel="noreferrer">Bain BEL</a></td>
                <td> <span class="badge badge-pill bg-success"> Open </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://www.bain.com/careers/meet-us/crew/" target="_blank" rel="noreferrer">Bain CREW (Women's Program)</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>
            
            <tr className="body-row">
                <td><a href="https://www.bcg.com/careers/join/on-campus/growing-future-leaders" target="_blank" rel="noreferrer">BCG GFL</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://careers.bcg.com/bcg-advance" target="_blank" rel="noreferrer">BCG Advance (Women's Program)</a></td>
                <td> <span class="badge badge-pill bg-success"> Open </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://eyglobal.yello.co/external/requisitions/N9IwA_1mcv6r3aph7etkAA" target="_blank" rel="noreferrer">EY-Parthenon Emerging Leaders</a></td>
                <td> <span class="badge badge-pill bg-success"> Open </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://www2.deloitte.com/us/en/pages/careers/articles/join-deloitte-discovery-internship.html" target="_blank" rel="noreferrer">Deloitte Discovery</a></td>
                <td> <span class="badge badge-pill bg-success"> Open  </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://jobs.us.pwc.com/search-jobs?acm=ALL&alrpm=ALL&ascf=[%7B%22key%22:%22is_manager%22,%22value%22:%22Start%22%7D,%7B%22key%22:%22industry%22,%22value%22:%22intern%22%7D]" target="_blank" rel="noreferrer">PwC Start</a></td>
                <td> <span class="badge badge-pill bg-success"> Open  </span></td>
            </tr>
            
        </table>
  </div>
    );
}

export default DiversityTable