import './Resources.css';
import EmailSignup from "../components/EmailSignup";
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

function Resources() {


    return (
      
      <>
      <Helmet>
        <title>MC List: Resources</title>
      </Helmet>

      <div className="description">
        <p>All of the resources below are ones we've used or have come highly recommended from friends who've landed top management consulting offers. 
          They will put you in the best position to ace the interview.</p>
    </div>

    {/* Community Section */}

      <div class="title">
            Groups
      </div>
      
     <div class="subtitle">Places you can go to connect and case with other (aspiring) management consultants.</div>
     <br></br>
      <div className="description">
        <p><a href="https://joinnova.co/c/home" target="_blank">Nova</a> is a community where many students and professionals share resources related to consulting. 
        You can find potential case partners by making a post or joining one of the many consulting message groups on the site. </p>
      </div>
  
    {/* Case Book Section */}

      <div class="title">
            Case Book Database
      </div>
      <br></br>
      <div className="description">
        <p>I've compiled a <a href="https://drive.google.com/drive/folders/1ibLuRvEk7pfcBRiKQ-Er5AS34-uWHC7A?usp=sharing" target="_blank"> database</a> of excellent case books for you to do mock cases from. Make sure to keep a running log of the cases you've done so you can track what your strengths and weaknesses are.</p>
      </div>
    
     {/* Interview Prep Book Section */}

     <div class="title">
            Interview Prep Resources
      </div>
      <div class="subtitle">Great books and websites for learning how to approach the case interview.</div>
      <br></br>
      <div className="description">
        <ul className="resources">
          <li><a href="https://www.amazon.com/Case-Interview-Secrets-Interviewer-Consulting/dp/0984183523" target="_blank"> Case Interview Secrets </a> </li>
          <p>Victor Cheng is terrific at breaking down the mechanics and flow of the case interview. The perfect first book to read as you prepare to case as it'll help you develop good initial frameworks and avoid many of the rookie mistakes people make when they begin.</p>
          <li><a href="https://www.craftingcases.com/" target="_blank"> Crafting Cases </a> </li>
          <p>Crafting Cases is a case prep website run by two ex-consultants who previously were at McKinsey and Bain. This site is terrific at teaching you how to approach market sizing questions as well as how to craft more advanced custom frameworks. 
            The free course offered on their website provides some very high level advice that will help set you apart in the case interview.</p>
          <li><a href="https://managementconsulted.com/case-study-samples/" target="_blank"> Management Consulted </a> </li>
          <p>Management Consulted is another case prep website founded by an ex-Bain consultant. This site is helpful for watching some good case interview examples and for their paid coaching services.</p>
          <li><a href="https://casecoach.com/blog/" target="_blank"> Case Coach </a> </li>
          <p>Case Coach offers a free resume course that is worth checking out as well as various resources on interview preparation. They also offer paid coaching services.</p>
        </ul>
      </div>

      <footer> 
      <EmailSignup/>
      </footer>

      </>
  
    );
  }
  
  export default Resources;