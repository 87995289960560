import "./EmailSignup.css";

function EmailSignup() {
    return (
        <>
         
           <div id="mc_embed_signup" class="centered card">
                <form action="https://gmail.us11.list-manage.com/subscribe/post?u=b034d0a91a863749ccfdea87b&amp;id=4fd0edba44&amp;f_id=00f597e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                <label for="mce-EMAIL">Get email updates when a management consulting role opens</label>
                
                <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Never miss an application deadline again"/>
                
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input type="text" name="b_b034d0a91a863749ccfdea87b_4fd0edba44" tabindex="-1" value=""/>
                </div>

                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/></div>
                </div>
                </form>
            </div>

        

        



        
         </>
      
      );
  }
  
  export default EmailSignup