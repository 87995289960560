import "./InternTable.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function InternTable() {
  return (
      
    <div className="InternTable">
        <table>
            <tr>
                <th>Company</th>
                <th>Status</th>
            </tr>

           
            <tr className="body-row">
                <td><a href="https://www.mckinsey.com/careers/search-jobs/jobs/businessanalystintern-15275" target="_blank" rel="noreferrer"> McKinsey</a></td>
                
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>  
            </tr>

            <tr className="body-row">
                <td><a href="https://www.bain.com/careers/roles/aci/#:~:text=We'll%20meet%20you%20on%20your%20campus&text=If%20you%20are%20from%20U.S.,at%2011%3A59%20PM%20PT." target="_blank" rel="noreferrer">Bain</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>
            
            <tr className="body-row">
                <td><a href="https://talent.bcg.com/en_US/apply/FolderDetail/Internship-Application/10013667" target="_blank" rel="noreferrer">BCG</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://jobs.us.pwc.com/job/los-angeles/strategy-and-consulting-intern-summer-2023/932/33431419968" target="_blank" rel="noreferrer">Strategy&</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://www.oliverwyman.com/careers/entry-level.html" target="_blank" rel="noreferrer">Oliver Wyman</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://www.lek.com/join-lek/apply/undergraduate" target="_blank" rel="noreferrer">L.E.K.</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://eyglobal.yello.co/external/requisitions/LOAxolpO8rXuNdZ-PwmMFw" target="_blank" rel="noreferrer">EY-Parthenon</a></td>
                <td> <span class="badge badge-pill bg-success"> Open </span></td>
            </tr>
            <tr className="body-row">
                <td><a href="https://kearney.recsolu.com/job_boards/1" target="_blank" rel="noreferrer">Kearney</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://apply.deloitte.com/careers/JobDetail/Deloitte-Consulting-Summer-Associate-Strategy-FY23/100463" target="_blank" rel="noreferrer">Deloitte</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://www.accenture.com/us-en/careers/jobdetails?id=R00098803_en&title=Summer+Analyst+(Intern)+Development+Programs+-+NAELFY23" target="_blank" rel="noreferrer">Accenture</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://studentjobs.ey.com/job/san-francisco/usa-consulting-business-consulting-intern-summer-2023/39053/35319087024" target="_blank" rel="noreferrer">EY</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>

            <tr className="body-row">
                <td><a href="https://jobs.us.pwc.com/job/los-angeles/management-consulting-intern-summer-2023/932/33431422160" target="_blank" rel="noreferrer">PwC</a></td>
                <td> <span class="badge badge-pill bg-danger"> Closed for 2023 </span></td>
            </tr>
            
        </table>
  </div>
    );
}

export default InternTable